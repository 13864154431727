import React, { useState, useEffect, useCallback } from "react";
import "./Principal.scss";
import Slider from "../Slider/Slider";
import { SwiperSlide } from "swiper/react";

const Principal = () => {
  const [y, setY] = useState(window.scrollY);
  const [showStiky, setShowStiky] = useState("ocultar");

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        if (window.scrollY <= 100) {
          setShowStiky("ocultar");
        }
      } else if (y < window.scrollY) {
        if (window.scrollY >= 100) {
          setShowStiky("mostrar");
        }
      }
      setY(window.scrollY);
    },
    [y]
  );
  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <div className="contenedor__principal">
      <div className="contenedor__principal_carousel">
        <Slider nav={false} pag={false}>
          <SwiperSlide>
            <div className="contenedor__principal_carousel__slider">
              <img src="./assets/Home/img_hero_punta-vela.jpg" alt="Punta vela exterior" />
            </div>
          </SwiperSlide>
        </Slider>
      </div>
      <div className="bottom_info">
        <div className="icon-scroll"></div>
        <div className="whatsapp">
          <div className={showStiky}>
            <a
              target={"_blank"}
              href="https://api.whatsapp.com/send?phone=+526566385985&text=Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20PuntaVela"
            >
              <img src="/assets/Home/iconos-logos/svg-icon-whatsapp.svg" alt="Whatsapp" />
            </a>
          </div>
        </div>
        <div className="texto">
          <p>CASAS RESIDENCIALES PREMIUM</p>
        </div>
      </div>
    </div>
  );
};

export default Principal;
