export const PrototypeTypes = {
  Alpe: "Alpe",
  Celta: "Celta",
  Mirabel: "Mirabel",
};

export const FloorType = {
  LowFloor: "Planta-Baja",
  TopFloor: "Planta-Alta",
};

const prototypes = {
  [PrototypeTypes.Alpe]: {
    name: "ALPE",
    terreno: "140",
    construccion: "146.5",
    gallery: ["/assets/Home/img-fachada_alpe-punta_vela.jpg"],
    floor: {
      [FloorType.LowFloor]: {
        title: "Planta Baja",
        features: [
          {
            characteristics: "Sala comedor",
          },
          {
            characteristics: "Cocina",
          },
          {
            characteristics: "Medio baño",
          },
          {
            characteristics: "Cuarto de lavado",
          },
          {
            characteristics: "Jardín trasero",
          },
          {
            characteristics: "2 cajones de estacionamiento",
          },
        ],
      },
      [FloorType.TopFloor]: {
        title: "Planta Alta",
        features: [
          {
            characteristics: "2 recámaras con baño y closet",
          },
          {
            characteristics:
              "1 recámara principal con baño, vestidor y terraza",
          },
        ],
      },
    },
  },
  [PrototypeTypes.Celta]: {
    name: "CELTA",
    terreno: "140",
    construccion: "147.66",
    gallery: ["/assets/Home/img-fachada_celta-punta_vela.jpg"],
    floor: {
      [FloorType.LowFloor]: {
        title: "Planta Baja",
        features: [
          {
            characteristics: "Sala comedor",
          },
          {
            characteristics: "Cocina",
          },
          {
            characteristics: "Medio baño",
          },
          {
            characteristics: "Cuarto de lavado",
          },
          {
            characteristics: "Jardín trasero con asador",
          },
          {
            characteristics: "2 cajones de estacionamiento",
          },
        ],
      },
      [FloorType.TopFloor]: {
        title: "Planta Alta",
        surface: "117.61",
        features: [
          {
            characteristics: "2 recámaras con baño y closet",
          },
          {
            characteristics:
              "1 recámara principal con baño, vestidor y terraza",
          },
        ],
      },
    },
  },
  [PrototypeTypes.Mirabel]: {
    name: "MIRABEL",
    terreno: "131",
    construccion: "106.9",
    gallery: ["/assets/Home/img-fachada_mirabel-punta_vela.jpg"],
    floor: {
      [FloorType.LowFloor]: {
        title: "Planta Baja",
        features: [
          {
            characteristics: "Sala comedor",
          },
          {
            characteristics: "Cocina",
          },
          {
            characteristics: "Medio baño",
          },
          {
            characteristics: "Jardín trasero",
          },
          {
            characteristics: "2 cajones de estacionamiento",
          },
        ],
      },
      [FloorType.TopFloor]: {
        title: "Planta Alta",
        features: [
          {
            characteristics: "2 recámaras",
          },
          {
            characteristics:
              "1 recámara principal con baño, vestidor y terraza",
          },
          {
            characteristics: "Medio baño",
          },
        ],
      },
    },
  },
};

export const getPrototype = (prototypeType) => prototypes[prototypeType] ?? {};
