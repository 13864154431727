import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import "./Galeria.scss";
const Galeria = (props) => {
  const handleShowModalGaleria = (tipo, url) => {
    props.setTypeGaleria(tipo);
    props.setUrlGaleria(url);
    props.setOpenGaleria(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="contenedor_galeria">
        <Link className="back" to={"/"}>X</Link>
      <div className="grid">
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/Home/Galeria/img_alpe-celta_punta-vela.jpg")
          }
        >
          <img src="/assets/Home/Galeria/img_alpe-celta_punta-vela.jpg" alt="" />
          <div className="caption">
            <p>FACHADA ALPE y CELTA</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/Home/Galeria/img_casa-club_punta-vela.jpg")
          }
        >
          <img src="/assets/Home/Galeria/img_casa-club_punta-vela.jpg" alt="" />
          <div className="caption">
            <p>CASA CLUB</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/Home/Galeria/img_entrad_punta-vela.jpg")
          }
        >
          <img src="/assets/Home/Galeria/img_entrad_punta-vela.jpg" alt="" />
          <div className="caption">
            <p>ENTRADA</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/Home/Galeria/img_entrada2_punta-vela.jpg")
          }
        >
          <img src="/assets/Home/Galeria/img_entrada2_punta-vela.jpg" alt="" />
          <div className="caption">
            <p>ENTRADA</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/Home/Galeria/img_gimnasio-sala de juegos_punta-vela.jpg")
          }
        >
          <img src="/assets/Home/Galeria/img_gimnasio-sala de juegos_punta-vela.jpg" alt="" />
          <div className="caption">
            <p>GYM</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/Home/Galeria/img_patio-trasero_punta-vela.jpg")
          }
        >
          <img src="/assets/Home/Galeria/img_patio-trasero_punta-vela.jpg" alt="" />
          <div className="caption">
            <p>PATIO</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/Home/Galeria/img_terraza_punta-vela.jpg")
          }
        >
          <img src="/assets/Home/Galeria/img_terraza_punta-vela.jpg" alt="" />
          <div className="caption">
            <p>TERRAZA</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/Home/Galeria/img-alpe_cocina-punta_vela.jpg")
          }
        >
          <img src="/assets/Home/Galeria/img-alpe_cocina-punta_vela.jpg" alt="" />
          <div className="caption">
            <p>COCINA</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/Home/Galeria/img-alpe_sala-comedor-punta_vela.jpg")
          }
        >
          <img src="/assets/Home/Galeria/img-alpe_sala-comedor-punta_vela.jpg" alt="" />
          <div className="caption">
            <p>SALA</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/Home/Galeria/img-mirabel_comedor-punta_vela-1.jpg")
          }
        >
          <img src="/assets/Home/Galeria/img-mirabel_comedor-punta_vela-1.jpg" alt="" />
          <div className="caption">
            <p>COMEDOR</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria("img", "/assets/Home/Galeria/imh_mirabel_punta-vela.jpg")
          }
        >
          <img src="/assets/Home/Galeria/imh_mirabel_punta-vela.jpg" alt="" />
          <div className="caption">
            <p>FACHADA MIRABEL</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Galeria;
