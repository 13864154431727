import React, { useState,useEffect } from 'react';
import './Recorrido.scss'
const Recorrido = () => {
    const [idScene, setId] = useState("alpe");

    const handleImagen = (tipo) =>{
        const removeElement = document.getElementsByClassName("active");
        removeElement[0].classList.remove("active")
        const element = document.getElementById(tipo);
        element.classList.add("active");
        if(tipo==="alpe"){
            setId("alpe")
        }else{
            if(tipo==="celta"){
                setId("celta")
            }else{
                if(tipo==="mirabel"){
                    setId("mirabel")
                }
            }
        }
    }

    return ( 
        <div id='recorrido' className='contenedor_recorrido'>
            {
                idScene ==="alpe" &&
                <iframe src='https://my.matterport.com/show/?m=hK4j9neVh46&play=1&nozoom=1&search=0&wh=0&f=0&qs=1&mt=0'/>
            }
                        {
                idScene ==="celta" &&
                <iframe src='https://my.matterport.com/show/?m=TTSNxyyp3R5&play=1&nozoom=1&search=0&wh=0&f=0&qs=1&mt=0' />
            }
                        {
                idScene ==="mirabel" &&
                <iframe src='https://my.matterport.com/show/?m=hAWNtEjdCrT&lang=es&play=1&nozoom=1&search=0&wh=0&f=0&qs=1&mt=0' />
            }
            <div className='options'>
                <button id="alpe" className="active" onClick={()=>{handleImagen("alpe")}}>ALPE</button>
                <button id="celta" onClick={()=>{handleImagen("celta")}}>CELTA</button>
                <button id="mirabel" onClick={()=>{handleImagen("mirabel")}}>MIRABEL</button>
            </div>
        </div>
     );
}
 
export default Recorrido;