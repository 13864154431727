import React, { useState } from "react";
import Header from "./Componentes/Header/Header";
import Footer from "./Componentes/Footer/Footer";
import Modal from "./Componentes/Modal/Modal";
import Home1 from "./Componentes/Home1/Home1.js";
import Aviso from "./Componentes/Aviso/Aviso";
import Video from "./Componentes/Video/Video";
import Mapa from "./Componentes/Mapa/Mapa";
import Galeria from "./Componentes/Galeria/Galeria.js";
import ModalGaleria from "./Componentes/Galeria/Modal.jsx";
import "./App.scss";
import { CSSTransition } from "react-transition-group";
import Stiky from "./Componentes/Stiky/Stiky";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
function App() {
  const [showModal, setModal] = useState(false);
  const [showModalAviso, setModalAviso] = useState(false);
  const [showVideo, setVideo] = useState(false);
  const [urlVideo, setUrlVideo] = useState("");
  const [open, setOpen] = useState("");
  const [OpenGaleria, setOpenGaleria] = useState(false);
  const [typeGaleria, setTypeGaleria] = useState("");
  const [urlGaleria, setUrlGaleria] = useState("");
  return (
    <div className={`App ${open}`}>
      <CSSTransition
        in={OpenGaleria}
        timeout={200}
        classNames="show"
        unmountOnExit
      >
        <ModalGaleria
          setOpenGaleria={setOpenGaleria}
          typeGaleria={typeGaleria}
          urlGaleria={urlGaleria}
        />
      </CSSTransition>
      <CSSTransition
        in={showModal}
        timeout={200}
        classNames="show"
        unmountOnExit
      >
        <Modal setModalAviso={setModalAviso} setModal={setModal} />
      </CSSTransition>
      <CSSTransition
        in={showModalAviso}
        timeout={200}
        classNames="show"
        unmountOnExit
      >
        <Aviso setModalAviso={setModalAviso} />
      </CSSTransition>
      <CSSTransition
        in={showVideo}
        timeout={200}
        classNames="show"
        unmountOnExit
      >
        <Video urlVideo={urlVideo} setVideo={setVideo} />
      </CSSTransition>
      <Stiky setModal={setModal} />
      <Header open={open} setOpen={setOpen}/>
      <Routes>
        <Route exact path="/" element={<Home1 setVideo={setVideo} setUrlVideo={setUrlVideo}/>} />
        <Route exact path="/galeria" element={<Galeria setUrlGaleria={setUrlGaleria} setOpenGaleria={setOpenGaleria} setTypeGaleria={setTypeGaleria}/>} />
      </Routes>
      <Mapa />
      <Footer setModalAviso={setModalAviso} />
    </div>
  );
}

export default App;
