import React,{useState, useEffect,useCallback} from 'react';
import './Stiky.scss'
const Stiky = (props) => {
    const [y, setY] = useState(window.scrollY);
    const [showStiky, setShowStiky] = useState('ocultar')
    const handleNavigation = useCallback(
        (e) => {
          const window = e.currentTarget;
          if (y > window.scrollY) {
              if(window.scrollY <= 550){
                setShowStiky('ocultar')
             }
          } else if (y < window.scrollY) {
              if(window.scrollY >= 550){
                setShowStiky('mostrar')
              }
          }
          setY(window.scrollY);
        },
        [y]
      );
    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
    
        return () => {
          window.removeEventListener("scroll", handleNavigation);
        };
      }, [handleNavigation]);

    return ( 
        <div className={`contenedor_stiky ${showStiky}`}>
            <div className='brochure'>
                <p onClick={()=>{props.setModal(true)}}>BROCHURE</p>
            </div>
            <div className='disponibilidad'>
                <a target='_blank' href='https://disponibilidad.puntavela-juarez.com'>DISPONIBILIDAD</a>
            </div>
        </div>
     );
}
 
export default Stiky;