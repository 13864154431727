import React,{useState} from 'react';
import './Mapa.scss';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import MapBox from '../MapBox/MapBox';

const Mapa = (props) => {
    const destinations = {
        ORIGEN:{
            lat: 20.509106231909826,
            lng: -100.40774736222625
        }
    }
    let [selectdestination, setDestination] = useState(null);
    return (
        <div id="ubicacion" className='contenedor_mapa'>
            <div className='mapa'>
                <MapBox/>
            </div>
            <div className='ubicaciones'>
                <div className='top'>
                <AnimationOnScroll
                        duration={2}
                        animateOnce={true}
                        animateIn='animate__fadeIn'>
                    <div className='top_subtitulo'>
                        <h2>CERCA DE <br/> TODO</h2>
                    </div>
                </AnimationOnScroll>
                    <div className='top_texto'>
                        <p>Av. Ramon Rayón #2510-6 Col. Ejido Salvarcar C.P. 32580 Ciudad Juárez, Chihuahua</p>
                    </div>
                </div>
                <div className='bottom'>
                    <div className='bottom_ubicaciones'>
                        <div className='row_list'>  
                            <p>A 1 minuto del boulevard 4 siglos</p>
                        </div>
                        <div className='row_list'>
                            <p>A 5 minutos de avenida Waterfill</p>
                        </div>
                        <div className='row_list'>
                            <p>A 10 minutos del puente Zaragoza</p>
                        </div>
                    </div>
                    <div className='mapas'> 
                        <a target={'_blank'} href="https://maps.app.goo.gl/8h425S2wFsB63taP7"><img src="/assets/Home/iconos-logos/svg-icon-maps.svg" alt="" /></a>
                        <a target={'_blank'} href="https://ul.waze.com/ul?ll=31.682418360452345%2C-106.34766897691019&navigate=yes&zoom=17&utm_campaign=waze_website&utm_source=waze_website&utm_medium=lm_share_location"><img src="/assets/Home/iconos-logos/svg-icon-waze.svg" alt="" /></a>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Mapa;