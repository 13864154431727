import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import "./Conceptos.scss";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
// import required modules
import { FreeMode, Pagination, Autoplay } from "swiper/core";
const Conceptos = ({
  type,
  title,
  description,
  setVideo,
  setUrlVideo,
  urlItem,
  arraySlider,
  style,
  buttonLink,
  buttonText,
  id
}) => {
  const handleVideo = () => {
    setVideo(true);
    setUrlVideo(urlItem);
  };
  return (
    <div id={id}className={`contenedor_conceptos ${type=='slider' ? 'reverse' : ''} ${style ?? style}`}>
      <div className="contenedor_conceptos__left">
        <AnimationOnScroll
          duration={2}
          animateOnce={true}
          animateIn="animate__fadeIn"
        >
          <div className="contenedor_conceptos__left_subtitulo">
            <h2>
              {title.map((item, index) => (
                <span key={index}>
                  {item} <br />
                </span>
              ))}
            </h2>
          </div>
        </AnimationOnScroll>
        <div className="contenedor_conceptos__left_texto">
          <p>{description}</p>
        </div>
        <div className="contenedor_conceptos__left_button">
          <a target={buttonLink === 'https://disponibilidad.puntavela-juarez.com' ? '_blank' : '_self'} href={buttonLink}>{buttonText}</a>
        </div>
      </div>
      {type == "video" && (
        <div className="contenedor_conceptos__right">
          <img
            className="play"
            src="/assets/Home/iconos-logos/Grupo 621.svg"
            alt=""
            onClick={() => handleVideo()}
          />
          <div className="contenedor_conceptos__right_video">
            <video
              src="/assets/Home/vid_intro_puntavela.mp4"
              autoPlay
              muted
              loop
            ></video>
          </div>
        </div>
      )}
      {type == "image" && (
        <div className="contenedor_conceptos__right">
          <div className="contenedor_conceptos__right_img">
            <img src={urlItem} alt="" />
          </div>
        </div>
      )}
      {type == "slider" && (
        <div className="contenedor_conceptos__right">
          <div className="contenedor_conceptos__right_slider">
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              spaceBetween={10}
              loop={true}
              centeredSlides={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 4000,
              }}
              modules={[FreeMode, Pagination, Autoplay]}
              className="concepto-carousel-slides"
            >
              {arraySlider.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="contenedor__principal_carousel__slider">
                    <img src={item} alt="Punta vela" />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
    </div>
  );
};

export default Conceptos;
