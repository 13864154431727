import React from 'react';
import './Galeria.scss'
const ModalGaleria = (props) => {
    return ( 
        <div className='modal-galeria'>
            <p className='closed' onClick={()=>props.setOpenGaleria(false)}>X</p>
            <div className='modalG'>
                {
                    props.typeGaleria === 'img' &&
                    <img src={props.urlGaleria} alt="" />
                }
                {
                    props.typeGaleria === 'video' &&
                    <video loop autoPlay muted src={props.urlGaleria} controls></video>
                }
            </div>
        </div>
     );
}
 
export default ModalGaleria;