import React from 'react';
import './Amenidades.scss';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
// import required modules
import { FreeMode, Pagination, Autoplay } from "swiper/core";
const Amenidades = () => {
    return ( 
        <div id="amenidades" className='contenedor_amenidades'>
            <div className='amenidades_top'>
            <AnimationOnScroll
                duration={2}
                animateOnce={true}
                animateIn='animate__fadeIn'
                className='animation-container'
                >
                <div className='subtitulo_ame'>
                    <h2>
                        AMENIDADES <br/> EXCLUSIVAS
                    </h2>
                    <p>Disfruta de un desarrollo privado con un ambiente único y amenidades exclusivas, todo en una ubicación privilegiada.</p>
                </div>
            </AnimationOnScroll>
                <div className='list_ame'>
                    <div className='colum_list'>
                        <img src="/assets/Home/iconos-logos/svg-casa_club-puntavela.svg" alt="casa club" />
                        <p>Casa club</p>
                    </div>
                    <div className='colum_list'>
                        <img src="/assets/Home/iconos-logos/svg-cuarto_juegos-punta-vela.svg" alt="cuarto de juegos" />
                        <p>Cuarto de juegos</p>
                    </div>
                    <div className='colum_list'>
                        <img src="/assets/Home/iconos-logos/svg-gimnasio-punta-vela.svg" alt="gym" />
                        <p>Gym</p>
                    </div>
                    <div className='colum_list'>
                        <img src="/assets/Home/iconos-logos/svg-piñatero-punta-vela.svg" alt="Piñatero" />
                        <p>Piñatero</p>
                    </div>
                    <div className='colum_list'>
                        <img src="/assets/Home/iconos-logos/svg-asadores-punta-vela.svg" alt="Asadores" />
                        <p>Área de asadores</p>
                    </div>
                    <div className='colum_list'>
                        <img src="/assets/Home/iconos-logos/svg-terraza-punta-vela.svg" alt="terraza" />
                        <p>Terraza de estar</p>
                    </div>
                    <div className='colum_list'>
                        <img src="/assets/Home/iconos-logos/svg-salon-punta-vela.svg" alt="Salon eventos" />
                        <p>Salón de eventos</p>
                    </div>
                </div>
            </div>
            <div id="galeria" className='amenidades_bottom'>
                <Swiper
                    style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                    }}
                    spaceBetween={10}
                    loop={true}
                    centeredSlides={true}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 4000,
                    }}
                    modules={[Pagination, Autoplay]}
                    className="concepto-carousel-slides"
                >
                    <SwiperSlide>
                        <div className="contenedor__amenidades_carousel__slider">
                            <img src="./assets/Home/img_1-vista_exterior_punta-vela.jpg" alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="contenedor__amenidades_carousel__slider">
                            <img src="./assets/Home/img_2-familia_punta-vela.jpg" alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="contenedor__amenidades_carousel__slider">
                            <img src="./assets/Home/img_3-casa_club_punta-vela.jpg" alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="contenedor__amenidades_carousel__slider">
                            <img src="./assets/Home/img_4-sala-juegos_punta-vela.jpg" alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="contenedor__amenidades_carousel__slider">
                            <img src="./assets/Home/img_5-gimnasio _velatowers.jpg" alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="contenedor__amenidades_carousel__slider">
                            <img src="./assets/Home/img_6-casa_club-patio_punta-vela.jpg" alt="" />
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
     );
}
 
export default Amenidades;