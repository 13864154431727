import React from "react"; 
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import './MapBox.scss'

const DEFAULT_MAP_POSITION = [31.682417729744053, -106.34766820696382];
const MapBox = () => {
    let DefaultIcon = L.icon({
        iconSize: [60,60],
        iconUrl: "./assets/Home/iconos-logos/svg-iso-puntavela.svg",
    });
    L.Marker.prototype.options.icon = DefaultIcon;
    return (  
        <>
        <MapContainer center={DEFAULT_MAP_POSITION} zoom={16} scrollWheelZoom={false}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://api.mapbox.com/styles/v1/bren95/cltt3ru2i00uf01qjdzbeawq2/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYnJlbjk1IiwiYSI6ImNsaHFpaTIzMjJmbXMzZm1tM3c3NDUwODQifQ.I63ww5KHzmVSwBiyl8zMrQ&zoomwheel=true&fresh=true#14.57/20.51052/-100.40175"
    />
    <Marker position={DEFAULT_MAP_POSITION}>
      <Popup>
        Punta Vela
      </Popup>
    </Marker>  
  </MapContainer>
        </>
    );
}
export default MapBox;