import React from 'react';
import './Footer.scss'
const Footer = (props) => {
    const currentYear = new Date().getFullYear();
    return ( 
        <div id="contacto" className='contenedor_footer'>
            <div className='contenedor_footer_social'>
                <a href="https://api.whatsapp.com/send?phone=+526566385985&text=Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20PuntaVela" target={'_blank'}><img src="/assets/Home/iconos-logos/svg-icon-whatsapp.svg" alt="whatsapp" /></a>
                <a href="https://www.facebook.com/puntavela" target={'_blank'}><img src="/assets/Home/iconos-logos/svg-icon-facebook.svg" alt="" /></a>
                <a href="https://www.instagram.com/puntavelajuarez/" target={'_blank'}><img src="/assets/Home/iconos-logos/svg-icon-instagram.svg" alt="" /></a>
            </div>
            <div className='contenedor_footer_logo'>
                <a href="https://grupovelas.com.mx/" target={'_blank'}><img src="/assets/Home/iconos-logos/svg-logo-grupo-velas.svg" alt="" /></a>
            </div>
            <div className='contenedor_footer_tel'>
                <a href="tel:+526566385985" target={'_blank'}>T. (656) 638 5985</a>
            </div>
            <div className='contenedor_footer_info'>
               <p> &copy; {currentYear} Grupo Velas ® Todos los derechos reservados | <a onClick={()=>{props.setModalAviso(true)}}>Política de privacidad</a></p>
               <p>El vendedor de Punta Vela se reserva el derecho de realizar modificaciones en los planos, diseños exteriores, precios, materiales, especificaciones, acabados y características estándar en cualquier momento y sin previo aviso. Las fotografías, las representaciones son ilustrativos y conceptuales.</p>
               <div><p>Diseño web por:</p><a href="https://renderinc.mx" target={'_blank'}><img src="/assets/Home/iconos-logos/svg-logo-renderinc.svg" alt="" /></a></div>
            </div>
        </div>
     );
}
 
export default Footer;